<template>
  <div class="row">
    <div class="col-12">
      <octo-header-button
        :buttons="headerButtons"
        @onNewCertificate="$router.push({name: 'courses.certificates.wizard'})"
      />
    </div>
    <div class="col-12">
      <certificate-datatable/>
    </div>
  </div>
</template>

<script>
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import CertificateDatatable from "./datatables/CertificateDatatable";

export default {
  name: "IndexCertificatePage",
  components: {CertificateDatatable, OctoHeaderButton},
  data() {
    return {
      headerButtons: [{
        label: 'add',
        onClick: 'onNewCertificate'
      }],
    }
  }
}
</script>

<style scoped>

</style>
