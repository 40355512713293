<template>
  <octo-table
    enable-export
    show-filters
    :show-id="false"
    :show-filters-collapse="false"
    :action="endpoints.DATATABLES.courses"
    :fields="fields"
    :filters="filters"
    :name="name"
    @onResetFilters="filters = {type: 'certificate'}"
  >
    <template v-slot:title="data">
      <div class="text-truncate">{{ data.row.title }}</div>
    </template>

    <template v-slot:slug="data">
      <div class="small">
        {{ data.row.slug || '' }}
      </div>
    </template>

    <template v-slot:active="data">
      <octo-icon :icon="data.row.active ? 'checked' : 'wrong'"/>
    </template>

    <template v-slot:price="data">
      {{ data.row.price | euro }}
    </template>

    <template v-slot:actions="data">
      <div class="d-flex justify-content-end">
        <base-button
          link
          icon
          class="mx-1"
          size="sm"
          @click="$router.push({name: 'courses.certificates.show', params: {id: data.row.id}})"
        >
          <octo-icon icon="right-arrow"/>
        </base-button>
      </div>
    </template>
  </octo-table>
</template>

<script>
import OctoTable from "@/components/octo-table/OctoTable";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {endpoints} from "@/routes/endpoints";

export default {
  name: "CertificateDatatable",
  components: {OctoIcon, OctoTable},
  data() {
    return {
      name: 'CertificateDatatable',
      endpoints: endpoints,
      filters: {
        type: 'certificate'
      },
      fields: [
        {prop: 'code', label: 'code', align: 'center', width: 150, fixed: true},
        {prop: 'title', label: 'title', minWidth: 300, slot: true,},
        {prop: 'slug', label: 'slug', slot: true, width: 350},
        {prop: 'active', label: 'active', slot: true, align: 'center'},
        {prop: 'price', label: 'price', width: 150, slot: true, align: 'center'},
        {prop: 'actions', label: 'actions', width: 100, slot: true, fixed: 'right'}
      ]
    }
  }
}
</script>

<style scoped>

</style>
